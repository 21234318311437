import * as React from 'react'

import { Grid } from 'components/common/grid'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { BlurValidation } from 'components/form/blur-validation'

import { requiredValidAge } from 'com.batch.common/validators'

import { type InputProps } from './helper'
import { type intervalUnit } from 'com.batch.redux/_records'
import {
  GreaterOperator,
  LowerOperator,
  LowerOrEqualOperator,
} from 'com.batch.redux/query/query.records.operators'
import { Hint } from 'com.batch/shared/ui/component/hint'

const allowedUnits: Array<intervalUnit> = ['d', 'h']

export const InputAge = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const showAgo = React.useMemo(
    () =>
      (condition.attribute?.type === 'DATE' || condition.attribute?.type === 'EVENT') &&
      condition.functions.filter(f => f.value === 'age' || f.value === 'lastBirthday').size >= 1,
    [condition.attribute?.type, condition.functions]
  )
  const isRetargeting = React.useMemo(
    () => ['be.click', 'be.open', 'be.sent'].includes(condition?.attribute?.api ?? ''),
    [condition?.attribute?.api]
  )
  const min = React.useMemo(() => {
    if (!isRetargeting) return -Infinity
    switch (condition.operator) {
      case LowerOperator:
        return 90 * 24 * 3600
      case LowerOrEqualOperator:
        return 89 * 24 * 3600
      default:
        return 3600
    }
  }, [condition.operator, isRetargeting])

  const max = React.useMemo(() => {
    if (!isRetargeting) return undefined
    switch (condition.operator) {
      case GreaterOperator:
        return 89 * 24 * 3600
      default:
        return 90 * 24 * 3600
    }
  }, [condition.operator, isRetargeting])

  const onAgeChange = React.useCallback(
    age => updateCondition(condition.set('value', condition.value.set('age', age))),
    [condition, updateCondition]
  )
  return (
    <Grid template="160px 20px auto">
      <BlurValidation validator={requiredValidAge} value={condition.value.age}>
        {({ onBlur, invalid }) => (
          <TimeIntervalInput
            invalid={isInvalid || invalid}
            onBlur={onBlur}
            min={min}
            max={max}
            age={condition.value.age}
            allowedUnits={allowedUnits}
            onChange={onAgeChange}
          />
        )}
      </BlurValidation>
      {showAgo && <span>ago</span>}
      {isRetargeting && <Hint tooltipWidth={160}>Retargeting data is kept 90 days</Hint>}
    </Grid>
  )
}
