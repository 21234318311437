import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Select } from 'components/form'
import { QueryBuilderContext } from 'components/query/query-builder.context'

import { OperatorAndNegateFormatter, type operatorAndNegate } from './left-helper'

import { AgeFactory } from 'com.batch.redux/_records'
import { getDefaultOperator, setInputType } from 'com.batch.redux/query/query.api'
import {
  FunctionParamsFactory,
  allFunctions,
  allOperators,
  type ConditionRecord,
} from 'com.batch.redux/query/query.records'
import { type FunctionRecord } from 'com.batch.redux/query/query.records.functions'
import {
  ExistsOperator,
  InOperator,
  EqualOperator,
  NotInOperator,
  StartsWithOperator,
  DoesNotContainSomeOperator,
  LowerOperator,
  LowerOrEqualOperator,
  GreaterOperator,
} from 'com.batch.redux/query/query.records.operators'

type LeftBaseProps = {
  condition: ConditionRecord
  updateCondition: (arg1: ConditionRecord) => void
  negated: boolean
  negate: () => void
  unNegate: () => void
}

type option = {
  label: string
  action: (arg1: ConditionRecord) => ConditionRecord
  valueMatcher: (arg1: ConditionRecord) => boolean
}
const optToString = (opt?: option | null) => opt?.label ?? ''
const optionOperatorToString = (opt?: operatorAndNegate | null) => opt?.operator.value ?? ''

const getLabel = (opt: option) => opt.label
export const LeftBase = ({
  condition,
  updateCondition,
  negated,
  negate,
  unNegate,
}: LeftBaseProps): React.ReactElement => {
  if (!condition.attribute) {
    throw 'invalid condition - no attribute'
  }
  const { context, isProfileMode } = React.useContext(QueryBuilderContext)
  const onOperatorChange = React.useCallback(
    opt => {
      if (opt) {
        updateCondition(
          setInputType(condition.set('operator', opt.operator)).set(
            'isEventFilterNegated',
            opt.negate
          )
        )
        if (opt.negate !== negated) {
          if (opt.negate) {
            negate()
          } else {
            unNegate()
          }
        }
      }
    },
    [condition, negate, negated, unNegate, updateCondition]
  )
  const onFunctionChange = React.useCallback(
    option => {
      if (option) updateCondition(option.action(condition))
    },
    [condition, updateCondition]
  )

  // Flow loses type refinement in the functions bellow, so we need ?.type ?? 'STRING'
  // even if it won't be used
  const availableFunctions = React.useMemo(
    () =>
      allFunctions.filter(
        func =>
          func.accept.has(condition.attribute?.type ?? 'STRING') &&
          func.allowedContext.has(context) &&
          (isProfileMode || func.value !== 'due')
      ),
    [condition.attribute, context, isProfileMode]
  )
  const availableOperators = React.useMemo(() => {
    const producedType = condition.functions
      .reverse()
      .reduce((type, func) => func.produce, condition.attribute?.type ?? 'STRING')
    return allOperators
      .filter(operator => {
        // si on a un de ces 2 attributes, on a pas le droit de prendre d'autre opérateurs
        if (
          condition.attribute?.api &&
          ['b.city_code', 'b.device_type'].includes(condition.attribute?.api)
        ) {
          return (
            [ExistsOperator, InOperator, NotInOperator].includes(operator) &&
            operator.accept.has(producedType)
          )
        }

        if (condition.attribute?.api && ['b.email_domain'].includes(condition.attribute?.api)) {
          return [EqualOperator, StartsWithOperator, InOperator, NotInOperator].includes(operator)
        }

        // si on est en mode position, on prend que exists
        if (condition.attribute?.api === 'b.position') return operator === ExistsOperator

        // exlude ExistsOperator from count and countSince functions
        if (
          condition.functions.first()?.value === 'countSince' ||
          condition.functions.first()?.value === 'count'
        ) {
          return operator !== ExistsOperator && operator.accept.has(producedType)
        }

        //  sinon on prend les opérateurs qui marchent avec le type produit, en cachant exists et doesNotContainSome sauf si doesNotContainSome est selectionné
        return (
          operator.accept.has(producedType) &&
          (operator !== DoesNotContainSomeOperator ||
            condition.operator.value === DoesNotContainSomeOperator.value)
        )
      })
      .map(operator => {
        // if next anniversary is the function, we need to change the age operator labels to future tense
        if (
          condition.functions.first()?.value === 'nextBirthday' ||
          condition.functions.first()?.value === 'due'
        ) {
          const futureTenseOperatorLabel = operator.label.replace('occurred', 'will occur in')
          return operator.set('label', futureTenseOperatorLabel)
        }
        return operator
      })
  }, [condition.attribute, condition.functions, condition.operator])
  const operatorWithNegate: List<operatorAndNegate> = React.useMemo(() => {
    const tmp: Array<operatorAndNegate> = []
    availableOperators.forEach(operator => {
      tmp.push({ operator, negate: false })
      if (operator.negate !== '') {
        if (
          condition.functions.first()?.value === 'nextBirthday' ||
          condition.functions.first()?.value === 'due'
        ) {
          const futureTenseOperatorLabel = operator.negate.replace(
            'did not occur',
            'will not occur in'
          )
          operator = operator.set('negate', futureTenseOperatorLabel)
          tmp.push({ operator, negate: true })
          return
        }
        tmp.push({ operator, negate: true })
      }
    })
    return Immutable.List(tmp)
  }, [availableOperators, condition.functions])
  const showExistsOperatorInFunctions = React.useMemo(
    () =>
      ExistsOperator.accept.has(condition.attribute?.type ?? 'STRING') &&
      condition.functions.size === 0,
    [condition.attribute?.type, condition.functions.size]
  )
  const fakeFunctionOptions: List<option> = React.useMemo(() => {
    if (availableFunctions.size === 0) return Immutable.List()
    const arr: option[] = []

    const generateLabel = (func: FunctionRecord): string => {
      if (condition.attribute?.type === 'DATE') {
        return func.label.toLowerCase()
      }
      if (condition.attribute?.type === 'EVENT' && func.value === 'age') {
        return 'last event'
      }
      return func.label
    }

    availableFunctions.forEach(func =>
      arr.push({
        label: generateLabel(func),
        valueMatcher: c =>
          c.functions.getIn([0, 'value']) === func.value ||
          c.functions.getIn([1, 'value']) === func.value,
        action: c =>
          setInputType(
            c
              .set('functions', Immutable.List<FunctionRecord>().push(func))
              .set('operator', getDefaultOperator(func.produce))
          ),
      })
    )
    if (showExistsOperatorInFunctions)
      arr.push({
        label:
          condition.attribute?.type === 'DATE'
            ? ExistsOperator.label.toLowerCase()
            : ExistsOperator.label,
        valueMatcher: (c: ConditionRecord) => c.operator === ExistsOperator,
        action: (c: ConditionRecord) =>
          setInputType(c.set('functions', Immutable.List()).set('operator', ExistsOperator)),
      })
    return Immutable.List(arr)
  }, [availableFunctions, condition.attribute?.type, showExistsOperatorInFunctions])

  const onAgeChange = React.useCallback(
    age => {
      updateCondition(condition.set('functionParams', FunctionParamsFactory({ age })))
    },
    [condition, updateCondition]
  )
  const isRetargeting = React.useMemo(
    () => ['be.click', 'be.open', 'be.sent'].includes(condition?.attribute?.api ?? ''),
    [condition?.attribute?.api]
  )
  const min = React.useMemo(() => {
    if (!isRetargeting) return -Infinity
    switch (condition.operator) {
      case LowerOperator:
        return 90 * 24 * 3600
      case LowerOrEqualOperator:
        return 89 * 24 * 3600
      default:
        return 3600
    }
  }, [condition.operator, isRetargeting])

  const max = React.useMemo(() => {
    if (!isRetargeting) return undefined
    switch (condition.operator) {
      case GreaterOperator:
        return 89 * 24 * 3600
      default:
        return 90 * 24 * 3600
    }
  }, [condition.operator, isRetargeting])
  return (
    <FlexLine>
      {fakeFunctionOptions.size > 1 && (
        <FlexLineItem>
          <Select
            style={{ width: 160 }}
            options={fakeFunctionOptions}
            value={fakeFunctionOptions.find(option => option.valueMatcher(condition), null)}
            optionToString={optToString}
            optionFormatter={getLabel}
            onChange={onFunctionChange}
          />
        </FlexLineItem>
      )}

      {condition.functions.first()?.value === 'countSince' && (
        <FlexLineItem>
          <TimeIntervalInput
            age={condition.functionParams?.age ?? AgeFactory({ unit: 'h' })}
            allowedUnits={['d', 'h']}
            onChange={onAgeChange}
            min={min}
            max={max}
          />
        </FlexLineItem>
      )}
      {availableOperators.size > 0 && condition.attribute?.api !== 'b.position' && (
        <FlexLineItem>
          <Select
            style={{
              width:
                condition.functions.first()?.value === 'nextBirthday' ||
                condition.functions.first()?.value === 'due'
                  ? 260
                  : 235,
            }}
            options={operatorWithNegate}
            optionToString={optionOperatorToString}
            optionFormatter={OperatorAndNegateFormatter}
            value={operatorWithNegate.find(
              ({ operator, negate }) =>
                operator.value === condition.operator.value && negated === negate
            )}
            onChange={onOperatorChange}
          />
        </FlexLineItem>
      )}
    </FlexLine>
  )
}
