import parseInt from 'core-js/es/number/parse-int'
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Input } from 'components/form'

import { type InputProps } from './helper'
import { useNumberInputValidation } from './use-validation'
import Hint from 'com.batch/shared/ui/component/hint'

export const InputInteger = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const { localValue, onChange, onBlur, isLocalInvalid } = useNumberInputValidation(
    condition,
    updateCondition,
    false
  )

  const showTimes = React.useMemo(
    () =>
      condition.attribute?.type === 'EVENT' &&
      condition.functions.filter(f => f.value === 'count' || f.value === 'countSince').size >= 1,
    [condition]
  )
  const showRetargetingHint = React.useMemo(
    () =>
      condition.functions.first()?.value === 'countSince' &&
      ['be.click', 'be.open', 'be.sent'].includes(condition?.attribute?.api ?? ''),
    [condition?.attribute?.api, condition.functions]
  )
  const plural = parseInt(localValue) === 0 || parseInt(localValue) > 1
  return (
    <Grid
      template={
        showRetargetingHint ? `80px ${plural ? 31 : 22}px 20px` : showTimes ? '80px 20px' : 'auto'
      }
    >
      <Input
        invalid={isInvalid || isLocalInvalid}
        type="number"
        onBlur={onBlur}
        value={localValue}
        onChange={onChange}
      />
      <span>{showTimes && (plural ? 'times' : 'time')}</span>
      {showRetargetingHint && (
        <span>
          <Hint tooltipWidth={160}>Retargeting data is kept 90 days</Hint>
        </span>
      )}
    </Grid>
  )
}
